import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, EventEmitter, Injector, Input, Output, effect, forwardRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';
import { extractTouchedChanges } from 'app/shared/utils/touch-listener';

@Component({
    selector: 'input-autocomplete-add',
    templateUrl: './input-autocomplete-add.component.html',
    styleUrls: ['./input-autocomplete-add.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslocoModule,
        MatIconModule,
        MatDialogModule,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputAutocompleteAddComponent),
            multi: true
        },
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => overlay.scrollStrategies.close,
            deps: [Overlay]
        }
    ]
})
export class InputAutocompleteAddComponent implements ControlValueAccessor {
    placeholder = input<string>('');
    label = input<string>('');
    valueKey = input<string>(null);
    labelKey = input<string>(null);
    labelKey2 = input<string>(null);
    options = input<any[]>([]);
    icon = input<string>('');
    @Input() showButton: boolean = true;
    @Input() showLabel: boolean = true;
    @Input() action($event: any): void {};
    @Output() clickIcon = new EventEmitter();
    required = signal<boolean>(false);
    private _value = signal<any>(null);
    protected _filteredOptions = signal<any[]>([]);
    protected _formControl = new FormControl();
    protected _ngControl: NgControl;
    private _internalChange = false;
    private _destroyRef$ = inject(DestroyRef);

    constructor(private _injector: Injector, public matDialog: MatDialog) {
        // With this we assurance that selected value is shown. It will change after
        // value, items or valueKey change so that async items are not a problem.
        effect(() => {
            if (!this._value() || !this.options()) return;
            let search: any = this.valueKey()
                ? this.options().find(x => x[this.valueKey()] === this._value())
                : this.options().find(x => x === this._value());
            if (!search) return;
            this._internalChange = true;
            this._formControl.setValue(search);

        })
    }

    onChange: (_: unknown) => void = () => {};
    onTouched: () => void = () => {};
    displayFn = (item: any) => {
        if (!item) return '';
        let label = ''
        if (this.labelKey()) label += item[this.labelKey()] ?? '';
        if (this.labelKey2() && item[this.labelKey2()]) {
            if (label) label += ' - ';
            label += item[this.labelKey2()];
        }
        return label;
    };

    ngOnInit(): void {
        // Get ngControl from injector to avoid circular dependency
        this._ngControl = this._injector.get(NgControl);
        this._formControl.valueChanges.subscribe(x => {
            if (this._internalChange) {
                this._internalChange = false;
                return;
            }
            if (!x) this.onChange(null);
            else this.onChange(this.valueKey() ? x[this.valueKey()] : x);
        });
        setTimeout(() => {
            this.updateValidations();
        }, 0)
    }

    updateValidations() {
        this._formControl.setErrors(this._ngControl.errors);
        this.required.set(this._ngControl.control.hasValidator(Validators.required));
        this._formControl.setValidators(this._ngControl.validator);
        this._ngControl.statusChanges.pipe(takeUntilDestroyed(this._destroyRef$)).subscribe(x => {
            this.required.set(this._ngControl.control.hasValidator(Validators.required));
            this._formControl.setErrors(this._ngControl.errors);
        })
        extractTouchedChanges(this._ngControl.control).pipe(takeUntilDestroyed(this._destroyRef$)).subscribe(x => {
            this._formControl.setErrors(this._ngControl.errors);
            this._formControl.markAsTouched();
        })
    }

    writeValue(obj: any): void {
        this._value.set(obj);
        if (!obj) {
            this._formControl.setValue(null);
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) this._formControl?.disable();
        else this._formControl?.enable();
    }

    updateOptions(value?: any): void {
        if (!value) {
            this._filteredOptions.set(this.options());
        } else {
            const searchKey = typeof value === 'string' ? value : value?.[this.labelKey()];
            this._filteredOptions.set(searchKey ? this._filter(searchKey as string) : this.options().slice());
        }
    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.options().filter(item =>
            (this.labelKey() && item[this.labelKey()] && item[this.labelKey()].toLowerCase().includes(filterValue)) ||
            (this.labelKey2() && item[this.labelKey2()] && item[this.labelKey2()].toLowerCase().includes(filterValue)) ||
            (this.displayFn(item).toLowerCase().includes(filterValue))
        );
    }
}
