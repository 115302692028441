import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { ProjectStatusOptions } from 'app/modules/project/constants/project.enum';
import { BillStateOptions } from 'app/modules/bill/constants/bill.enum';

@Injectable({
    providedIn: 'root'
})
export class GeneralUtils {
    getRowById(id: string | number): HTMLElement {
        return document.getElementById('row-' + id);
    }

    focusOnRow(rowId: string | number) {
        const element = document.getElementById('row-' + rowId) ?? null;
        if (element) {
            element.focus();
        }
    }

    stringOrEmpty(value: string): string {
        return value ?? '';
    }

    withoutNulls(obj: any): any {
        Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key]);
        return obj;
    }

    objectHasValues(obj: any): boolean {
        if (obj == null) return false;
        return Object.values(obj).some((x) => x != null);
    }

    getColorByStatus(status: string): string {
        switch (status) {
            case ProjectStatusOptions.PENDIENTE:
            return 'pending';
            case ProjectStatusOptions.EN_EJECUCION:
            return 'execution';
            case ProjectStatusOptions.FINALIZADO:
            return 'finalized';
            case ProjectStatusOptions.CANCELADO:
            return 'cancelled';
            default:
                return 'default';
        }
    }

    getColorByStatusBill(status: string): string {
        switch (status) {
            case BillStateOptions.PENDIENTE:
            return 'pending';
            case BillStateOptions.COBRADA:
            return 'finalized';
            default:
                return 'default';
        }
    }

    isOldDate(dateStr: string): boolean {
        if (dateStr === '') {
            return false;
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora a medianoche para comparar solo fechas
        const eventDate = new Date(dateStr);
        return eventDate < today;
    }

    getDateString(date: Date | string) {
        if (date instanceof Date) {
            return date.toDateString();
        } else if (typeof date === 'string') {
            const dateObject = new Date(date);
            if (!isNaN(dateObject.getTime())) {
                return dateObject.toLocaleString('es-ES', { timeZone: 'UTC' }).split(', ')[0].toString();
            } else {
                console.error('La cadena no es una fecha válida.');
            }
        } else {
            console.error('Tipo de fecha no válido.');
        }
    }

    compareObjects(obj1: any, obj2: any): boolean {
        return isEqual(this.withoutNulls(obj1), this.withoutNulls(obj2));
    }

    compareProperties(obj1: any, obj2: any, properties: string[]): boolean {
        const obj1Filtered = this.filterProperties(obj1, properties);
        const obj2Filtered = this.filterProperties(obj2, properties);

        const obj1WithoutNulls = this.withoutNulls(obj1Filtered);
        const obj2WithoutNulls = this.withoutNulls(obj2Filtered);

        const areEqual = isEqual(obj1WithoutNulls, obj2WithoutNulls);
        return areEqual;
    }

    filterProperties(obj: any, properties: string[]): any {
        const result: any = {};

        properties.forEach(property => {
            const keys = property.split('.');
            let currentObj = obj;
            let currentResult = result;

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (currentObj && key in currentObj) {
                    if (Array.isArray(currentObj[key])) {
                        // Filtramos los elementos del array si es necesario
                        currentResult[key] = currentObj[key].map((item: any) => {
                            if (typeof item === 'object') {
                                return this.filterProperties(item, properties.map(prop => prop.split('.').slice(i + 1).join('.')).filter(Boolean));
                            }
                            return item; // Si el item es un valor primitivo (string o number), lo devolvemos directamente
                        });
                        break; // Salimos del loop si encontramos un array
                    } else {
                        if (i === keys.length - 1) {
                            // Última clave, asignamos el valor
                            currentResult[key] = currentObj[key];
                        } else {
                            // Continuamos navegando por los objetos
                            currentResult[key] = currentResult[key] || {};
                            currentObj = currentObj[key];
                            currentResult = currentResult[key];
                        }
                    }
                } else {
                    break; // Si no encontramos la propiedad, salimos del loop
                }
            }
        });

        return result;
    }

    darkenHexColor(hexColor: string, percent: number): string {
        // Parsear el color hexadecimal a RGB.
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);

        // Aplicar la disminución en la intensidad del color.
        const darkenedR = Math.floor((r * (100 - percent)) / 100);
        const darkenedG = Math.floor((g * (100 - percent)) / 100);
        const darkenedB = Math.floor((b * (100 - percent)) / 100);

        // Convertir de nuevo a formato hexadecimal.
        const darkenedHex = `#${darkenedR.toString(16)}${darkenedG.toString(16)}${darkenedB.toString(16)}`;

        return darkenedHex;
    }

    lightenHexColor(hexColor: string, percent: number): string {
        // Parsear el color hexadecimal a RGB.
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);

        // Aplicar el aumento en la intensidad del color.
        const lightenedR = Math.min(Math.floor((r * (100 + percent)) / 100), 255);
        const lightenedG = Math.min(Math.floor((g * (100 + percent)) / 100), 255);
        const lightenedB = Math.min(Math.floor((b * (100 + percent)) / 100), 255);

        // Convertir de nuevo a formato hexadecimal.
        const lightenedHex = `#${lightenedR.toString(16)}${lightenedG.toString(16)}${lightenedB.toString(16)}`;

        return lightenedHex;
    }
}
