import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UriUtils } from '../../shared/utils/uri.util';
import { Categories } from '../interfaces/categories.interface';
import { Observable, Subject, of, switchMap, take } from 'rxjs';
import { BaseService } from './base.service';
import { Order, Pagination } from '../interfaces';
import { UserService } from '../user/user.service';

@Injectable()
export class CategoriesService extends BaseService<Categories> {

    constructor(_httpClient: HttpClient, _userService: UserService) {
        super('v1/categories', _httpClient, _userService);
    }

    findIn(filter: Categories, page: number = 1, limit: number = 10, order: Order = { column: 'name', typeOrder: 'ASC' }): Observable<Pagination<Categories>> {
        return super.findIn(filter, page, limit, order);
    }

    catalog() {
        return this._httpClient
            .get<Categories[]>(UriUtils.buildUrl(this.url, 'catalog'))
    }

    create(item : Categories) {
        return this._httpClient.post<Categories>(UriUtils.buildUrl(this.url), item).pipe(take(1));
    }
}
