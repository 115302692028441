<mat-form-field class="mat-form-field-container">

    @if(label()) {
        <mat-label>
            {{ label() }}
            @if(required()) {
                <span class="mat-mdc-form-field-required-marker mdc-floating-label--required"></span>
            }
        </mat-label>
    }
    <input
        #input
        type="text"
        matInput
        [placeholder]="placeholder()"
        [matAutocomplete]="auto"
        [formControl]="_formControl"
        (keyup)="updateOptions(input.value)"
        (focus)="updateOptions(input.value)"
    />
    <mat-autocomplete
        #auto="matAutocomplete"
        requireSelection
        [displayWith]="displayFn"
    >
        <mat-option *ngFor="let item of _filteredOptions()" [value]="item">
            {{ displayFn(item) }}
        </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="_ngControl.hasError('required')">
        {{ 'common.error.required' | transloco }}
    </mat-error>
    <mat-icon *ngIf="icon().length > 0" class="icon-size-5" [svgIcon]="icon()" matPrefix></mat-icon>
    <button *ngIf="showButton && _ngControl.enabled" mat-icon-button (click)="action($event)" type="button" matSuffix>
        <mat-icon class="icon-size-5" svgIcon="heroicons_outline:plus-circle"></mat-icon>
    </button>
</mat-form-field>
